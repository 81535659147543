import React, { useState, useEffect } from "react";
import { PROPERTIES, PROPERTY, USER } from "../constants.ts";
import Header from "../components/common/Header";
import DownArrow from "../components/common/DownArrow.jsx";
import ListComponent from "../components/common/ListComponent.jsx";
import ReviewCarousel from "../components/common/ReviewCarousal.jsx";
import ContactForm from "../components/ContactForm.jsx";
import Footer from "../components/common/Footer.jsx";
import { Link } from "react-router-dom";

function HomePage() {
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    unit: "",
    lastName: "",
    property: PROPERTY.AV,
  });
  const baseURL = "https://mystique-uh-assets.s3.us-west-2.amazonaws.com";

  const [neighborHoodList, setNeighborHoodList] = useState([
    {
      link: "https://www.tcu.edu/",
      title: "Texas Christian University (TCU)",
      distance: "0.6 miles",
      driveTime: "2 min Drive",
    },
    {
      link: "https://www.dfwi.org/",
      title: "Downtown Ft.Worth",
      distance: "4.4 miles",
      driveTime: "14 min Drive",
    },
    {
      link: "https://maps.app.goo.gl/Z6YQxhDKkKgn72WSAs",
      title:
        "Walking distance to shopping, restaurants, and public transportation",
      distance: "0.2 mile",
      driveTime: "1 min Drive",
    },
    {
      link: "https://maps.app.goo.gl/JE1Pnw7TWVG1vBeWA",
      title: "Walmart",
      distance: "1.2 miles",
      driveTime: "4 min Drive",
    },
    {
      link: "https://www.bswhealth.com/locations/family-medicine-fort-worth?utm_source=google-mybusiness&utm_medium=organic&utm_campaign=9597&utm_content=main-website&y_source=1_MTQ2NjI5MjktNzE1LWxvY2F0aW9uLndlYnNpdGU%3D",
      title: "Baylor Scott & White Family Medicine",
      distance: "1.2 miles",
      driveTime: "4 min Drive",
    },
    {
      link: "https://www.fortworthzoo.org/",
      title: "Fort Worth Zoo, Colonial Country Club, and Stockyards",
      distance: "2 miles",
      driveTime: "7 min Drive",
    },
  ]);
  const [amenetiesList, setAmenetiesList] = useState([
    {
      title: "Fitness Center",
      distance: "open 24 hours",
      driveTime: "on-premise",
    },
    {
      title: "Swimming Pool",
      distance: "open 10-9pm",
      driveTime: "on-premise",
    },
    {
      title: "Club house with free Wi-Fi, coffee bar, and business center",
      distance: "open 10-9pm",
      driveTime: "on-premise",
    },
    {
      title: "Pool table, shuffleboard, and outdoor grilling area",
      distance: "open 10-9pm",
      driveTime: "on-premise",
    },
    {
      title: "Gated community with controlled access",
      distance: "Parking lock keys provided",
      driveTime: "on-premise",
    },
    {
      title: "Washing machine and dryer in every unit",
      distance: "contact office for more details",
      driveTime: "on-premise",
    },
  ]);

  // Preload critical background images
  useEffect(() => {
    const images = ["mystique", "tcufootball", "pool", "fps", "gym"];

    images.forEach((imageName) => {
      const img = new Image();
      img.src = `${baseURL}/${imageName}.jpg`; // Adjust the path/extension as needed
    });
  }, []);

  return (
    <>
      <div className="animate-fadeIn w-full h-full overflow-x-hidden">
        <div className="flex flex-col absolute z-10 w-full">
          <section className="HEADER">
            <Header />
          </section>
        </div>
        <div className="BODY">
          <div className="bg-mystique bg-cover w-full h-[70vh] bg-center bg-no-repeat">
            <img
              src={`${baseURL}/mystique.jpg`}
              className="hidden"
              loading="eager"
              alt=""
            />
            <div className="text-white flex items-center justify-center h-full w-full shadow-md">
              <div className="w-full h-full flex flex-col items-center justify-center backdrop-brightness-[0.6] px-4">
                <h1 className="font-bold text-xl sm:text-2xl lg:text-3xl xl:text-5xl text-center">
                  LUXURY APARTMENTS
                </h1>
                <h3 className="mt-2 text-xs sm:text-sm lg:text-lg xl:text-xl text-center">
                  IN FORT WORTH, TX
                </h3>
                <div className="mt-10 flex items-center gap-x-6">
                  <Link
                    to="/contact"
                    className="rounded-md bg-black px-3.5 py-2.5 text-sm font-semibold text-white shadow-md hover:bg-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                  >
                    Get started
                  </Link>
                  <Link
                    to="/floorplans"
                    className="text-sm font-semibold leading-6 text-white"
                  >
                    Floor Plans <span aria-hidden="true">→</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <DownArrow targetId={"neighborhood"} />

          {/* SECTION 2 Neighborhood */}
          <section
            id="neighborhood"
            className="flex flex-col items-center mt-10 md:mt-20 text-black w-full animate-fadeIn"
          >
            <h1 className="text-3xl md:text-5xl font-light">Neighborhood</h1>
            <div className="container xl:grid xl:grid-flow-col xl:grid-cols-2 xl:gap-8 mt-8 w-full h-full">
              <div className="bg-tcufootball bg-contain bg-no-repeat h-32 md:h-96 flex items-end"></div>
              <ListComponent listData={neighborHoodList} />
            </div>
          </section>

          {/* SECTION 3 Gallery/Floorplan */}
          <section
            id="gallery"
            className="grid grid-cols-1 sm:grid-flow-col sm:grid-cols-2 gap-4 items-center text-black h-auto sm:h-[40vh] animate-fadeIn mt-10 md:mt-20 px-4"
          >
            <div className="bg-pool bg-cover h-48 sm:h-full bg-gray-400 shadow-xl rounded-sm transform transition-transform duration-300 hover:scale-110 cursor-pointer">
              <Link to="/gallery">
                <div className="w-full h-full flex justify-center items-center backdrop-brightness-[0.7] hover:backdrop-brightness-75">
                  <div className="flex flex-col text-white p-4 sm:p-8 text-center">
                    <h2 className="text-2xl sm:text-3xl md:text-5xl font-semibold mb-2 sm:mb-4">
                      Gallery
                    </h2>
                    <p className="text-xs sm:text-sm md:text-lg">
                      Explore our Amenities, neighborhood and more
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="bg-fps bg-cover h-full bg-gray-400 shadow-md rounded-sm transform transition-transform duration-300 hover:backdrop-brightness-75 hover:scale-110 cursor-pointer">
              <Link to="/floorplans">
                <div className="w-full h-full flex justify-center items-center backdrop-brightness-[0.7]">
                  <div className="flex flex-col text-white p-8">
                    <h2 className="text-3xl md:text-5xl font-semibold mb-4">
                      Floorplans
                    </h2>
                    <p className="text-xs md:text-lg">
                      {" "}
                      We have 1 and 2 bedrooms floorplans to choose from
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </section>
          {/* SECTION 4 AMENETIES */}
          <section
            id="ameneties"
            className="flex flex-col items-center mt-10 md:mt-20 text-black w-full animate-fadeIn bg-gradient-to-r from-cyan-500 to-blue-500"
          >
            <h1 className="text-3xl md:text-5xl font-light">Ameneties</h1>
            <div className="container flex flex-col xl:grid xl:grid-flow-col xl:grid-cols-2 xl:gap-8 mt-8 w-full h-full">
              <ListComponent listData={amenetiesList} />
              <div className="bg-gym bg-cover flex items-end h-60 w-full md:h-full"></div>
            </div>
          </section>
          {/* SECTION 4 REVIEWS */}
          <section className="mt-10 md:mt-20">
            <ReviewCarousel />
          </section>
          <section className="bg-pool bg-cover bg-bottom w-full h-[60vh] my-20"></section>
          {/* SECTION 4 Contact */}
          <section className="bg-white dark:bg-gray-900 mt-20">
            <ContactForm />
          </section>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default HomePage;
