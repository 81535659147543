import React, { useState } from "react";
import { Link } from "react-router-dom";

const Box = ({ floorplans }) => {
  const openImage = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };

  const [showDetails, setShowDetails] = useState(false);

  // State to store the details
  const [details] = useState([
    { feature: "Washer/Dryer" },
    { feature: "Washer/Dryer Hookup" },
    { feature: "Air Conditioning" },
    { feature: "Cable Ready" },
    { feature: "Security System" },
    { feature: "Tub/Shower" },
    { feature: "Surround Sound" },

    // Kitchen Features & Appliances
    { feature: "Dishwasher" },
    { feature: "Disposal" },
    { feature: "Ice Maker" },
    { feature: "Granite Countertops" },
    { feature: "Stainless Steel Appliances" },
    { feature: "Pantry" },
    { feature: "Island Kitchen" },
    { feature: "Kitchen" },
    { feature: "Refrigerator" },
    { feature: "Freezer" },

    // Floor Plan Details
    { feature: "Balcony" },
    { feature: "Hardwood Floors" },
    { feature: "Vinyl Flooring" },
    { feature: "Dining Room" },
    { feature: "High Ceilings" },
    { feature: "Built-In Bookshelves" },
  ]);

  // Function to toggle the floor plan details
  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <>
      <div className="flex flex-col w-full h-full xl:ml-40 ">
        <p className="bottom-0 text-sm mt-4">
          All prices are subject to the market, please contact the office via
          phone or email for current day price
        </p>
        {floorplans.map((floorplan) => (
          <div
            key={floorplan.id}
            className="flex w-full h-full px-4 mb-4 justify-center items-center mt-20 "
          >
            <div className="border flex flex-col md:flex-row justify-between px-8 h-full w-full border-gray-400 rounded-md bg-transparent p-4 shadow-md">
              {/* Left Section */}
              <div className="h-full w-full  pt-8 gap-8 flex flex-col justify-between">
                <div className="flex flex-col gap-2">
                  <h2 className="text-2xl font-bold mb-1">{floorplan.title}</h2>
                  <ul>
                    {floorplan.details.map((detail, index) => (
                      <li key={index}>{detail}</li>
                    ))}
                  </ul>

                  <div
                    onClick={toggleDetails}
                    className=" text-blue-500 font-bold text- cursor-pointer"
                  >
                    {showDetails
                      ? "Hide Floor Plan Details"
                      : "Show Floor Plan Details"}
                  </div>
                  {showDetails && (
                    <div className="p-2">
                      <ul className="grid grid-col-cols grid-cols-3 w-full h-full text-sm gap-2">
                        {details.map((item, index) => (
                          <li key={index}>{item.feature}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <Link to="/contact" className="text-md  text-blue-500">
                  Please contact office for pricing details
                </Link>
              </div>
              {/* Right Section */}
              <div
                className=" w-full md:w-1/3 flex flex-col items-center cursor-pointer"
                onClick={() => openImage(floorplan.image)}
              >
                <img
                  src={floorplan.image}
                  alt={floorplan.title}
                  className="h-auto rounded-md "
                />
                <p>click to expand</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Box;
