import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import HomePage from "./pages/homepage.tsx";
localStorage.theme = "light";

function App() {
  return (
    <>
      <HomePage />
    </>
  );
}

export default App;
