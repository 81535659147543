import React from "react";
import { Link } from "react-router-dom";

const ContactProperty = () => {
  return (
    <div className="flex w-full items-center justify-center  sticky top-0">
      <div className="bg-white  mt-20 w-2/3 flex flex-col justify-center items-center">
        <div className="mb-4">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Contact This Property
          </h3>
        </div>
        <div className="grid gap-4">
          <Link
            to="/contact"
            className="bg-black text-white text-sm leading-6 font-medium py-2 px-4 rounded-md "
          >
            Request Tour
          </Link>
          <Link
            to="/contact"
            className="bg-gray-200 text-gray-700 text-sm leading-6 font-medium py-2 px-4 rounded-md hover:bg-gray-300 focus:outline-none focus:bg-gray-300"
          >
            Send Message
          </Link>

          <div className="flex flex-col gap-2">
            <span className="text-sm text-gray-700">(817) 782-9753</span>

            <span className="text-sm text-gray-700">
              Language: English and Spanish
            </span>
            <span className="text-sm text-gray-700">
              Mon-Friday 9:00am - 5:00pm Sat- 10:00am - 3:00pm Sun- Closed
            </span>
            <span className="text-sm text-gray-700">
              2901 Stanley Ave, Fort Worth, TX 76110
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactProperty;
