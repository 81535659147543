// DownArrow.js
import React from "react";
import { Link as ScrollLink } from "react-scroll";

const DownArrow = ({ targetId }) => {
  return (
    <ScrollLink
      to={targetId}
      smooth={true}
      duration={500}
      className="flex justify-center items-center pt-8 cursor-pointer"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-6 h-6 text-gray-500 hover:text-gray-900"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 14l-7 7m0 0l-7-7m7 7V3"
        />
      </svg>
    </ScrollLink>
  );
};

export default DownArrow;
