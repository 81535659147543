import React from "react";
import { IoIosStar } from "react-icons/io";

const ReviewCard = ({ review }) => {
  const starsArray = Array.from({ length: review.stars }, (_, index) => (
    <IoIosStar key={index} style={{ color: "#ffe234" }} />
  ));

  return (
    <div className="card ">
      <figure className="rounded-2xl bg-white shadow-xl ring-1 ring-gray-900/5 sm:col-span-2 xl:col-start-2 xl:row-end-1 ">
        <blockquote className="p-6 text-sm md:text-lg font-semibold leading-7 tracking-tight text-gray-900 sm:p-12 sm:text-xl sm:leading-8">
          <p>{review.content}</p>
        </blockquote>
        <figcaption className="flex flex-wrap items-center gap-x-4 gap-y-4 border-t border-gray-900/10 px-6 py-4 sm:flex-nowrap">
          <div className="flex justify-between px-2 w-full">
            <div className="font-semibold">{review.author}</div>
            <div className="flex">{starsArray}</div>
          </div>
        </figcaption>
      </figure>
    </div>
  );
};

export default ReviewCard;
