import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-black dark:bg-gray-900">
      <div className="container px-6 py-12 mx-auto">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
          <div className="sm:col-span-2">
            <h1 className="max-w-lg  text-sm md:text-xl font-semibold tracking-tight text-white xl:text-2xl dark:text-white">
              Subscribe our newsletter to get update.
            </h1>

            <div className="flex flex-col mx-auto mt-6 space-y-3 md:space-y-0 md:flex-row">
              <input
                id="email"
                type="text"
                className="px-4 py-2 text-gray-700 bg-white border rounded-md dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:outline-none focus:ring focus:ring-opacity-40 focus:ring-blue-300"
                placeholder="Email Address"
              />

              <button className="w-full px-6 py-2.5 text-sm font-medium tracking-wider text-white transition-colors duration-300 transform md:w-auto md:mx-4 focus:outline-none bg-gray-800 rounded-lg hover:bg-gray-700 focus:ring focus:ring-gray-300 focus:ring-opacity-80">
                Subscribe
              </button>
            </div>
          </div>

          <div>
            <div className="flex flex-col items-start space-y-2">
              <a
                href="https://mars.myresman.com/Portal/Access/SignIn/MUH"
                className="text-white transition-colors duration-300 "
              >
                Residents
              </a>
              <Link
                to="/gallery"
                className="text-white transition-colors duration-3"
              >
                Gallery
              </Link>
              <Link
                to="/floorplans"
                className="text-white transition-colors duration-3"
              >
                Floorplans
              </Link>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div className=" text-white">Mon-Friday 9:00am - 5:00pm</div>
            <div className="flex flex-col items-start  space-y-2">
              <div className=" text-white">(817) 782-9753</div>
              <div className=" text-white">
                2901 Stanley Ave, Fort Worth, TX 76110
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
