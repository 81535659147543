export const PROPERTIES = [
  { label: "Meadow Ridge", value: "MR" },
  { label: "Newport Landing", value: "NL" },
  { label: "Avalon 8801", value: "AV" },
  { label: "6500 South", value: "6500" },
  { label: "Colony on Rochelle", value: "CLR" },
  { label: "The Colony", value: "CLNY" },
  { label: "La Ventana on Rochelle", value: "LVR" },
  { label: "Hidden Oasis", value: "HO" },
  { label: "Westwood", value: "WW" },
  { label: "Vista Del Sol", value: "VDS" },
  { label: "Casa Bella", value: "CB" },
  { label: "Hidden Oasis", value: "HO" },
  { label: "University Gardens", value: "UG" },
  { label: "University Heights", value: "UH" },
  { label: "Basswood Manor", value: "BM" },
];

export enum PROPERTY {
  MR = "MR",
  NL = "NL",
  AV = "AV",
  SIXTYFIVE = "6500",
  CLR = "CLR",
  CLY = "CLNY",
  LVR = "LVR",
  WW = "WW",
  VDS = "VDS",
  CB = "CB",
  HO = "HO",
  UG = "UG",
  UH = "UH",
  BM = "MB",
}

export type USER = {
  unit: string;
  first_name: string;
  last_name: string;
  property: PROPERTY;
  cashId: string;
};

export const TABLE_HEAD = [
  "Property",
  "Unit",
  "Carpet Cleaning",
  "Trash Out",
  "Paint Job",
  "Make Ready",
  "Housekeeping",
  "CounterTop",
  "Tiles",
  "Pest Control",
  "Comments",
];

export const ADD_TABLE_HEAD = [
  "",
  "Property",
  "Unit",
  "Carpet Cleaning",
  "Trash Out",
  "Paint Job",
  "Make Ready",
  "Housekeeping",
  "CounterTop",
  "Tiles",
  "Pest Control",
  "Comments",
];
