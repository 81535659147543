import React, { useState } from "react";
import Header from "../components/common/Header";
import Box from "../components/common/Box";
import ContactProperty from "../components/ContactProperty";
import Footer from "../components/common/Footer";
import emailjs from "emailjs-com";

function Contact() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const clearFormData = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
    });
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      const emailAddress = formData.email;

      await emailjs.send(
        "service_rf3yvmm",
        "template_vpiudvp",
        {
          name: formData.firstName + " " + formData.lastName,
          email: emailAddress,
          message: formData.message,
          phone: formData.phone,
        },
        "7c6Q8Tw0MuQ0pqBVq"
      );

      // Clear the form data and display success message
      clearFormData();
      setSuccessMessage("We got your email. Will get back to you shortly!");
      setErrorMessage(""); // Clear any previous error message
    } catch (error) {
      // Display error message
      setSuccessMessage("");
      setErrorMessage(
        "Error while sending email, please call the office. Thank you!"
      );
    }
  };
  return (
    <>
      <div className="w-screen h-full">
        {" "}
        <div className="flex flex-col absolute">
          <section className="HEADER ">
            <Header />
          </section>
        </div>
        <div className="px-6 sm:px-40 pt-10 sm:pt-20 pb-10 sm:pb-20 bg-mystique">
          <h1 className="text-2xl sm:text-4xl font-bold mb-2 sm:mb-4 text-white mt-10 sm:mt-20">
            CONTACT US
          </h1>
          <p className="mb-4 text-white">
            We love a call, but stop by during office hour!
          </p>
        </div>
        <section className="grid grid-cols-1  gap-4 sm:gap-8  items-center justify-center my-20 w-full">
          <div className="container mx-auto p-4 w-full ">
            <div className="grid  gap-8 w-full md:grid-cols-7">
              <div className="col-span-2" />
              <div className="col-span-3">
                <h2 className="text-3xl font-semibold mb-4">Contact Us</h2>
                <p className="mb-8">
                  We try to get back to you as soon as possible, but feel free
                  to call/ email us as well!
                </p>
                <form onSubmit={sendEmail}>
                  <div className="mb-4">
                    <label
                      htmlFor="firstName"
                      className="block text-gray-700 text-sm font-bold mb-2"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      placeholder="Bonnie"
                      className="shadow appearance-none border rounded w-full py-4 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="lastName"
                      className="block text-gray-700 text-sm font-bold mb-2"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      placeholder="Green"
                      className="shadow appearance-none border rounded w-full py-4 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-gray-700 text-sm font-bold mb-2"
                    >
                      Your email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="name@flowbite.com"
                      className="shadow appearance-none border rounded w-full py-4 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="phone"
                      className="block text-gray-700 text-sm font-bold mb-2"
                    >
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="+12 345 6789"
                      className="shadow appearance-none border rounded w-full py-4 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div className="mb-8">
                    <label
                      htmlFor="message"
                      className="block text-gray-700 text-sm font-bold mb-2"
                    >
                      Your message
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows="4"
                      placeholder="Leave a comment..."
                      className="shadow appearance-none border rounded w-full py-4 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    ></textarea>
                  </div>
                  <div className="mb-4">
                    <input
                      type="submit"
                      value="Send message"
                      className="bg-black hover:bg-gray-900 w-full text-white font-bold py-4 px-4 rounded focus:outline-none focus:shadow-outline"
                    />
                  </div>
                </form>
                {successMessage && (
                  <p className="text-green-500 font-bold">{successMessage}</p>
                )}

                {errorMessage && (
                  <p className="text-red-500 font-bold">{errorMessage}</p>
                )}
              </div>
              <div className="col-span-2">
                <div className="mb-4">
                  <h3 className=" font-semibold mb-2">Address:</h3>
                  <p className="text-sm">2901 Stanley Ave, Fort Worth, TX </p>
                  <p className="text-sm">Zip Code/Postal code:76110</p>
                </div>
                <div className="mb-4">
                  <h3 className=" font-semibold mb-2">Call us:</h3>
                  <p className="text-sm">(817) 782-9753</p>
                </div>
                <div className="mb-4">
                  <h3 className=" font-semibold mb-2">Email us:</h3>
                  <p className="text-sm">
                    universityheights@marsresidential.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default Contact;
