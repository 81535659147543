import React from "react";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import ImageGallery from "../components/ImageGallery";

function Gallery() {
  return (
    <div>
      <div className="animate-fadeIn w-screen h-full">
        <div className="flex flex-col absolute w-full z-10">
          <section className=" HEADER ">
            <Header />
          </section>
        </div>
        <div className="md:px-40  px-10 py-10 md:pt-20 md:pb-20 bg-mystique">
          <h1 className="text-xl md:text-4xl font-bold mb-2 sm:mb-4 text-white mt-20">
            GALLERY
          </h1>
          <p className="mb-8 text-white text-xs md:text-lg">
            Pictures are great, but everything is better in person. Feel free to
            stop by during office hours!
          </p>
        </div>

        <section className="">
          <ImageGallery />
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default Gallery;
