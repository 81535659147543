import React, { useState } from "react";
import { Link } from "react-router-dom";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="w-full">
      <div className="flex justify-between items-center p-3 sm:p-4 md:p-8 md:pr-20 z-10">
        <Link to="/">
          <div className="font-italic font-white text-white font-light italic text-sm sm:text-base">
            Mystique at University Heights
          </div>
        </Link>
        {/* Hamburger Icon */}
        <div className="md:hidden">
          <button
            onClick={toggleMenu}
            className="text-white cursor-pointer focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {menuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
        {/* Desktop Menu */}
        <div className="hidden md:flex gap-8 ">
          <Link to="/">
            <div className="text-white font-light font-sans text-md cursor-pointer hover:font-bold">
              Home
            </div>
          </Link>
          <Link to="/floorplans">
            <div className="text-white font-light font-sans text-md cursor-pointer hover:font-bold">
              Floor plans
            </div>
          </Link>
          <Link to="/gallery">
            <div className="text-white font-light font-sans text-md cursor-pointer">
              Gallery
            </div>
          </Link>
          <a href="https://mars.myresman.com/Portal/Access/SignIn/MUH">
            <div className="text-white font-light font-sans text-md cursor-pointer">
              Residents
            </div>
          </a>
          <Link to="/contact">
            <div className="text-white font-light font-sans text-md cursor-pointer">
              Contact
            </div>
          </Link>
        </div>
      </div>
      {/* Mobile Menu */}
      {menuOpen && (
        <div className="md:hidden">
          <div className="bg-white p-4 flex flex-col gap-4">
            <Link to="/">
              <div className="text-black font-light font-sans text-md cursor-pointer hover:font-bold">
                Home
              </div>
            </Link>
            <Link to="/floorplans">
              <div className="text-black font-light font-sans text-md cursor-pointer hover:font-bold">
                Floor plans
              </div>
            </Link>
            <Link to="/gallery">
              <div className="text-black font-light font-sans text-md cursor-pointer">
                Gallery
              </div>
            </Link>
            <a href="https://mars.myresman.com/Portal/Access/SignIn/MUH">
              <div className="text-black font-light font-sans text-md cursor-pointer">
                Residents
              </div>
            </a>
            <Link to="/contact">
              <div className="text-black font-light font-sans text-md cursor-pointer">
                Contact
              </div>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
