import React, { useState } from "react";
import ReviewCard from "./ReviewCard";
import { SlArrowLeft } from "react-icons/sl";
import { SlArrowRight } from "react-icons/sl";

const reviewsData = [
  {
    author: "Emily Johnson",
    content: "Manager is great at the property. Great maintenance team.",
    stars: 5,
  },

  {
    author: "Michael Rodriguez",
    content: " I love the location and the amenities.",
    stars: 5,
  },

  {
    author: "Sarah Williams",
    content:
      "Spacious and well-designed apartments. The neighborhood is quiet, and I feel safe. Definitely recommend!",
    stars: 4,
  },

  {
    author: "Raj Patel",
    content:
      "Close to TCU, I walk to campus every day. The staff is friendly and helpful.",
    stars: 4,
  },

  {
    author: "Jessica Martinez",
    content: "I've lived here for a year, and it's been wonderful. ",
    stars: 5,
  },

  {
    author: "Ryan Taylor",
    content:
      " The leasing office is responsive, and the overall atmosphere is great for families or individuals.",
    stars: 5,
  },
];

const ReviewCarousel = () => {
  const [currentSet, setCurrentSet] = useState(0);

  const reviewsPerPage = 3;
  const totalSets = Math.ceil(reviewsData.length / reviewsPerPage);

  const nextSet = () => {
    setCurrentSet((prevSet) => (prevSet + 1) % totalSets);
  };

  const prevSet = () => {
    setCurrentSet((prevSet) => (prevSet === 0 ? totalSets - 1 : prevSet - 1));
  };

  const startIndex = currentSet * reviewsPerPage;
  const endIndex = startIndex + reviewsPerPage;

  const visibleReviews = reviewsData.slice(startIndex, endIndex);

  return (
    <div className="review-carousel flex items-center mx-auto max-w-screen-xl px-4">
      <button className="cursor-pointer hidden sm:block" onClick={prevSet}>
        <SlArrowLeft />
      </button>
      <div className="carousel-container flex flex-col sm:flex-row gap-4 transition-transform duration-500 ease-in-out mx-2 sm:mx-4 overflow-x-hidden">
        {visibleReviews.map((review, index) => (
          <div key={index} className={`slide opacity-100`}>
            <ReviewCard review={review} />
          </div>
        ))}
      </div>
      <button className="cursor-pointer" onClick={nextSet}>
        <SlArrowRight />
      </button>
    </div>
  );
};

export default ReviewCarousel;
